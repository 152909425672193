import { ReactNode } from 'react'
import { Input } from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'
import NumberFormat, { FormatInputValueFunction, NumberFormatValues, SourceInfo } from 'react-number-format'

export interface IFormItemProps {
  id?: string
  name: string
  label: string
  type?: string
  format?: string | FormatInputValueFunction
  mask?: string | string[]
  placeholder?: string
  prefix?: string
  suffix?: string
  thousandSeparator?: boolean
  defaultValue?: number | string
  onValueChange?: (values: NumberFormatValues, sourceInfo: SourceInfo) => void
  isSmallLabel?: boolean
  isRequired?: boolean
  children?: ReactNode
  disabled?: boolean
}

const FormItemWithMask = (props: IFormItemProps) => {
  const {
    id,
    name,
    label,
    type,
    format,
    mask,
    placeholder,
    prefix,
    suffix,
    thousandSeparator,
    defaultValue,
    onValueChange,
    isRequired = true,
    disabled,
  } = props
  const { control } = useFormContext()

  let pattern
  switch (name) {
    case 'ssn':
      //*INFO: validate ***-**-****
      pattern = {
        value: /^\d{3}-\d{2}-\d{4}$/,
        message: 'Please enter a valid SSN',
      }
      break
    case 'dateOfBirth':
      //*INFO: validate (MM/DD/YYYY), with a year between 1900 and 2099
      pattern = {
        value: /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/,
        message: 'Please enter a valid date',
      }
      break
    case 'month':
      //*INFO: validate month: between 1 and 12
      pattern = {
        value: /^(0[1-9]|1[012])$/,
        message: 'Invalid month',
      }
      break
    case 'day':
      //*INFO: validate day: between 1 and 31
      pattern = {
        value: /^(0[1-9]|[12][0-9]|3[01])$/,
        message: 'Invalid day',
      }
      break
    case 'year':
      //*INFO: validate year: between 1900 and 2099
      pattern = {
        value: /^(19|20)\d\d$/,
        message: 'Invalid year',
      }
      break
    default:
      pattern = undefined
  }

  const disabledProps = disabled
    ? {
        disabled: true,
        background: 'gray.100',
        opacity: '0.7 !important',
        color: 'gray.400',
        variant: 'filled',
      }
    : {}
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired ? `${label} is required` : false, pattern }}
      render={({ field: { onChange, onBlur, value } }) => (
        <Input
          id={id}
          paddingY={0}
          borderRadius="6px"
          _focus={{
            boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
          }}
          _disabled={{
            border: '1px solid #E2E8F0',
          }}
          as={NumberFormat}
          type={type}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          format={format}
          mask={mask}
          prefix={prefix}
          suffix={suffix}
          thousandSeparator={thousandSeparator}
          defaultValue={defaultValue}
          onValueChange={onValueChange}
          autoComplete={name}
          placeholder={placeholder}
          {...disabledProps}
        />
      )}
    />
  )
}

export default FormItemWithMask
