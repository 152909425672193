import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { AxiosResponse } from 'axios'
import { IPolicyGroupsByMonth, IPolicyGroupsByMonthAdminRequest } from 'types/policy'
import { IPolicy } from 'constants/schema'
import { ICreatePolicyResponse } from './constants'

export async function getPolicyGroupsByMonthAdmin(
  payload?: IPolicyGroupsByMonthAdminRequest
): Promise<IPolicyGroupsByMonth[]> {
  try {
    const url = APIRouters.policy.list.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getPolicyGroupsByMonthUser(): Promise<IPolicyGroupsByMonth[]> {
  try {
    const url = APIRouters.policy.list.value
    const response: AxiosResponse = await apiV2.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function updatePolicyAPI(payload: IPolicy, policyId: string): Promise<void> {
  try {
    await apiV2.post(APIRouters.policy.update.policyId.value(policyId), payload)
  } catch (error) {
    throw error
  }
}

export async function deletePolicyAPI(policyId: string): Promise<void> {
  try {
    await apiV2.post(APIRouters.policy.delete.policyId.value(policyId))
  } catch (error) {
    throw error
  }
}

export async function createPolicyAPI(payload: IPolicy): Promise<ICreatePolicyResponse> {
  try {
    const url: string = APIRouters.policy.create.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}
