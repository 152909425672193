import { handleError } from 'API/error'
import { createPolicyAPI, deletePolicyAPI, getPolicyGroupsByMonthAdmin, updatePolicyAPI } from 'API/policy'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { IPolicyFilter, IPolicyGroupsByMonth } from 'types/policy'
import { Messages } from 'constants/index'
import { IPolicy } from 'constants/schema'
import RootStore from '../rootStore'

class AdminPolicyStore {
  rootStore: RootStore
  policyGroupsByMonth: IPolicyGroupsByMonth[]
  isLoading: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async createPolicy(payload: IPolicy) {
    try {
      await createPolicyAPI(payload)
      toast.success(Messages.createPolicySuccess)
    } catch (error) {
      handleError(error, 'src/store/admin/policyStore.ts', 'createPolicy', false, true)
    }
  }

  public async getPolicyGroupsByMonth(filter?: IPolicyFilter) {
    try {
      this.isLoading = true
      const data = await getPolicyGroupsByMonthAdmin({ filter })
      this.policyGroupsByMonth = data
      this.isLoading = false
    } catch (error) {
      handleError(error, 'src/store/admin/policyStore.ts', 'getPolicyGroupsByMonth')
    }
  }

  public async updatePolicy(payload: IPolicy, policyId: string) {
    try {
      await updatePolicyAPI(payload, policyId)
    } catch (error) {
      handleError(error, 'src/store/admin/policyStore.ts', 'updatePolicy')
    }
  }

  public async deletePolicy(policyId: string) {
    try {
      await deletePolicyAPI(policyId)
      toast.success(Messages.deletePolicySuccess)
    } catch (error) {
      handleError(error, 'src/store/admin/policyStore.ts', 'deletePolicy', false, true)
    }
  }
}

export default AdminPolicyStore
