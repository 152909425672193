import { FormControl, InputGroup, FormLabel, FormErrorMessage } from '@chakra-ui/react'
import { GroupBase, CreatableSelect } from 'chakra-react-select'
import { DropdownStyles } from 'components/Styles/DropdownStyles'
import { useFormContext, Controller } from 'react-hook-form'
import { IOption } from 'constants/common'
import { IChakraInputDropdownProps } from '../Dropdown/types'

const SingleSelect = (props: IChakraInputDropdownProps) => {
  const {
    name,
    label,
    optionsData,
    isDisabled,
    placeHolder,
    isRequire,
    widthCustom,
    isClearable = false,
    isCreatable,
    containerStyle,
    menuPlacement,
  } = props
  const { control } = useFormContext()
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: isRequire ? 'This field is required.' : false }}
      render={({ field: { onChange, name, ref, value }, fieldState: { error } }) => {
        return (
          <FormControl width="full" isInvalid={!!error} id={name}>
            {label && (
              <FormLabel fontWeight={'normal'} mb={2} color="gray.700">
                {label}
              </FormLabel>
            )}
            <InputGroup
              borderRadius="6px"
              width={{
                sm: widthCustom ? widthCustom : '100%',
                md: widthCustom ? widthCustom : '100%',
                lg: widthCustom ? widthCustom : '100%',
              }}
              bg="white">
              <CreatableSelect<IOption, true, GroupBase<IOption>>
                name={name}
                ref={ref}
                placeholder={placeHolder}
                isDisabled={isDisabled}
                colorScheme="gray.700"
                value={value}
                options={optionsData}
                isValidNewOption={(value) => isCreatable && !!value}
                closeMenuOnSelect={true}
                size="md"
                isClearable={isClearable}
                onChange={onChange}
                menuPlacement={menuPlacement}
                chakraStyles={DropdownStyles(isDisabled, {}, {}, containerStyle)}
              />
            </InputGroup>
            <FormErrorMessage>{error && error.message}</FormErrorMessage>
          </FormControl>
        )
      }}
    />
  )
}

export default SingleSelect
