import { View, EventApi } from '@fullcalendar/core'

export enum ECalendarViewType {
  TIME_GRID_DAY = 'timeGridDay',
  TIME_GRID_WEEK = 'timeGridWeek',
  DAY_GRID_MONTH = 'dayGridMonth',
}

//* INFO: Follow interface of @fullcalendar
export interface IEventRenderInfo {
  isMirror: boolean
  isStart: boolean
  isEnd: boolean
  event: EventApi
  el: HTMLElement
  view: View
}

//* INFO: Follow interface of @fullcalendar
export interface IDayRenderInfo {
  view: View
  date: Date
  allDay?: boolean
  el: HTMLElement
}

export interface ICalendarClickEvent {
  el: HTMLElement
  event: EventApi
  jsEvent: MouseEvent
  view: View
}

export interface ICalendarSelectInfo {
  start: Date
  end: Date
  startStr: string
  endStr: string
  allDay: boolean
  jsEvent: MouseEvent
  view: View
}

export interface ICalendarTimeData {
  start: Date
  end: Date
}
