import { forwardRef } from 'react'
import { CalendarIcon } from '@chakra-ui/icons'
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import ReactDatePicker from 'react-datepicker'
import './styles.scss'
import { borderSecondary } from 'theme/globalStyles'
import { IDatePickerProps } from './interfaces'

const customDateInput = (
  { value, onClick, onChange, style, disabled, showMonthYearPicker, showYearPicker }: any,
  ref: any
) => {
  const borderColor = style?.borderColor ?? borderSecondary
  let defaultValue = 'mmm dd, yyyy'
  if (showMonthYearPicker) {
    defaultValue = 'mmm yyyy'
  }
  if (showYearPicker) {
    defaultValue = 'yyyy'
  }
  return (
    <InputGroup onClick={onClick} cursor="pointer">
      <Input
        autoComplete="off"
        value={value || defaultValue}
        ref={ref}
        style={style}
        disabled={disabled}
        onChange={onChange}
        _focus={{
          border: `1px solid ${borderColor}`,
          boxShadow: `0 0 0 1px ${borderColor} !important`,
          zIndex: 2,
          borderRadius: '8px',
        }}
        readOnly={false}
      />
      <InputRightElement color="gray.500" children={icon} />
    </InputGroup>
  )
}
customDateInput.displayName = 'DateInput'

const CustomInput = forwardRef(customDateInput)

const icon = <CalendarIcon fontSize="sm" />

const DatePicker = (props: IDatePickerProps) => {
  const {
    isClearable,
    onChange,
    selectedDate,
    showPopperArrow,
    placement,
    style,
    disabled,
    showMonthYearPicker,
    showYearPicker,
  } = props
  let dateFormat = 'MMM dd, yyyy'
  if (showMonthYearPicker) {
    dateFormat = 'MMM yyyy'
  }
  if (showYearPicker) {
    dateFormat = 'yyyy'
  }
  return (
    <ReactDatePicker
      selected={selectedDate}
      onChange={onChange}
      isClearable={isClearable}
      isSearchable={true}
      disabled={disabled}
      showPopperArrow={showPopperArrow}
      className="react-datepicker"
      showDatePicker={!showMonthYearPicker && !showYearPicker}
      showMonthYearPicker={showMonthYearPicker}
      showYearPicker={showYearPicker}
      dateFormat={dateFormat}
      popperPlacement={placement}
      customInput={
        <CustomInput
          style={style}
          disabled={disabled}
          showMonthYearPicker={showMonthYearPicker}
          showYearPicker={showYearPicker}
        />
      }
    />
  )
}

export default DatePicker
