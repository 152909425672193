import { frontendRoutes } from 'constants/routes'
import {
  IMenuList,
  IRouteName,
  timesheetSubmenuAdmin,
  timesheetSubmenuSupperVisor,
  timesheetSubmenuUser,
  userDashboardSubmenuOfUser,
  userDashboardSubmenuOfSupervisor,
} from './constant'

export function getRouteName(isAdmin: boolean): IRouteName {
  const route: IRouteName = {
    userRoute: isAdmin ? frontendRoutes.userPage.list.value : '',
    partner: isAdmin ? frontendRoutes.partnerPage.list.value : '',
    timesheetRoute: isAdmin
      ? frontendRoutes.timesheetPage.timesheetAdmin.overview.value
      : frontendRoutes.timesheetPage.board.value,
    projectRoute: isAdmin
      ? frontendRoutes.projectPage.projectAdmin.list.value
      : frontendRoutes.projectPage.projectUser.list.value,
    internalDocument: isAdmin ? frontendRoutes.internalDocument.list.value : '',
    paymentRoute: isAdmin
      ? frontendRoutes.paymentPage.paymentAdmin.value
      : frontendRoutes.paymentPage.paymentUser.value,
    policyRoute: isAdmin ? frontendRoutes.policyPage.policyAdmin.value : frontendRoutes.policyPage.policyUser.value,
    announcementRoute: isAdmin
      ? frontendRoutes.announcementPage.announcementAdmin.value
      : frontendRoutes.announcementPage.announcementUser.value,
    diagramRoute: isAdmin ? frontendRoutes.diagramPage.diagramAdmin.value : '',
    linkToHome: isAdmin ? frontendRoutes.adminDashboardPage.value : frontendRoutes.userDashboardPage.personal.value,
    qrCodeRoute: isAdmin ? frontendRoutes.qrCodePage.value : '',
    dashboardRoute: isAdmin ? frontendRoutes.adminDashboardPage.value : frontendRoutes.userDashboardPage.personal.value,
  }
  return route
}

export function getTimesheetSubmenu(isAdmin: boolean, isSupervisor: boolean): IMenuList[] {
  if (isAdmin) {
    return timesheetSubmenuAdmin
  }
  if (!isAdmin && isSupervisor) {
    return timesheetSubmenuSupperVisor
  }
  return timesheetSubmenuUser
}

export function getUserDashboardSubmenu(isSupervisor: boolean): IMenuList[] {
  if (isSupervisor) {
    return userDashboardSubmenuOfSupervisor
  }
  return userDashboardSubmenuOfUser
}

export function handleRedirect(path: string, currentPath: string, handleCollapse: () => void): void {
  if (currentPath === path) {
    handleCollapse()
  }
}
