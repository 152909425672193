import { handleError } from 'API/error'
import { getPolicyGroupsByMonthUser } from 'API/policy'
import { makeAutoObservable } from 'mobx'
import RootStore from 'store/rootStore'
import { IPolicyGroupsByMonth } from 'types/policy'

class UserPolicyStore {
  rootStore: RootStore
  policyGroupsByMonth: IPolicyGroupsByMonth[]
  isLoading: boolean = false
  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getPolicyGroupsByMonth() {
    try {
      this.isLoading = true
      const data = await getPolicyGroupsByMonthUser()
      this.policyGroupsByMonth = data
      this.isLoading = false
    } catch (error) {
      handleError(error, 'src/store/user/policyStore.ts', 'getPolicyGroupsByMonth')
    }
  }
}

export default UserPolicyStore
