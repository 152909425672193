/* eslint-disable max-lines */
import { FC } from 'react'
import { chakra, shouldForwardProp } from '@chakra-ui/react'
import isValidHTMLProp from '@emotion/is-prop-valid'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import queryString from 'query-string'
import { BaseStyle } from 'types/common'
import { IOption } from 'types/creatableOption'
import { EActionType } from 'constants/enum'
import { AppConfig } from 'constants/index'

dayjs.extend(utc)
dayjs.extend(timezone)

export function checkAccessFE(aToken, rToken) {
  if (!aToken && !rToken) {
    return false
  }
  const aTokenArray = aToken.split(' ')
  let isValid = true
  if (Array.isArray(aTokenArray) && aTokenArray[0] !== rToken) {
    isValid = false
  }
  if (Array.isArray(aTokenArray) && aTokenArray[2] !== AppConfig.DEFAULT_TOKEN) {
    isValid = false
  }
  return isValid
}

export function getIndicesOf(searchStr, str, caseSensitive) {
  let searchStrLen = searchStr.length
  if (searchStrLen === 0) {
    return []
  }
  let startIndex = 0,
    index,
    indices = []
  if (!caseSensitive) {
    str = str.toLowerCase()
    searchStr = searchStr.toLowerCase()
  }
  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index)
    startIndex = index + searchStrLen
  }
  return indices
}

function convertLineBreaksAndLists(htmlString) {
  let updatedHtml = htmlString
    .replace(/<br\s*\/?>/gi, '\n')
    .replace(/<\/p>|<\/li>/gi, '\n')
    .replace(/<li>/gi, '- ')
    .replace(/<ul>|<\/ul>|<p>|<head>.*?<\/head>|<body>|<\/body>/gi, '')
  updatedHtml = updatedHtml.replace(/\n\s*\n/g, '\n').replace(/\s{2,}/g, ' ')
  return updatedHtml.trim()
}

export function stripHtml(htmlString) {
  const updatedHtml = convertLineBreaksAndLists(htmlString)
  const doc = new DOMParser().parseFromString(updatedHtml, 'text/html')
  let textContent = doc.body.textContent || ''
  return textContent.trim()
}

export function copyToClipboardHandler(shareCVURL) {
  // Create new element
  var el = document.createElement('textarea')
  // Set value (string to be copied)
  el.value = shareCVURL
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '')
  el.style = { position: 'absolute', left: '-9999px' }
  document.body.appendChild(el)
  // Select text inside element
  el.select()
  // Copy text to clipboard
  document.execCommand('copy')
  // Remove temporary element
  document.body.removeChild(el)
}

export function beforeLogoutHandler() {
  setTimeout(function () {
    window.sessionStorage.clear()
    window.localStorage.clear()
    window.location.reload()
  }, 1000)
}

export function isValidArray(array?: unknown[]): boolean {
  return array ? Array.isArray(array) && array.length > 0 : false
}

export function getValidArray<T>(array?: T[]): T[] {
  return isValidArray(array) ? array || [] : []
}

export function parseJson(inputJSON: string): object {
  let outputJSON: object
  try {
    outputJSON = JSON.parse(inputJSON)
  } catch (error) {
    outputJSON = {}
  }
  return outputJSON
}

export function appendFileExtension(blobType: string, fileName: string): string {
  let extension = ''

  switch (blobType) {
    case 'image/png':
      extension = '.png'
      break
    case 'image/jpeg':
    case 'image/jpg':
      extension = '.jpg'
      break
    case 'image/svg+xml':
      extension = '.svg'
      break
    case 'image/gif':
      extension = '.gif'
      break
    case 'image/bmp':
      extension = '.bmp'
      break
    case 'image/webp':
      extension = '.webp'
      break
    default:
      extension = ''
      break
  }

  let finalFileName = fileName

  if (!fileName.endsWith(extension)) {
    finalFileName += extension
  }

  return finalFileName
}

export function isInputCorrect(inputValue: string | undefined, shortName: string | undefined): boolean {
  return inputValue === shortName
}

export function getModalTitle(actionType: string | undefined, type: string): string | null {
  if (actionType === EActionType.CREATE) {
    return `Create New ${type}`
  }
  if (actionType === EActionType.EDIT) {
    return `Edit ${type}`
  }
  return null
}

export function isFileSizeOverKB(size: number, file: File): boolean {
  if (file.size > size * 1024) {
    return true
  }
  return false
}

export function toUpperCaseWithUnderscore(value: string): string {
  return value.toUpperCase().replace(/\s/g, '_')
}

export function getInQuery(array: any[]) {
  if (array && array.length > 0) {
    return {
      $in: array,
    }
  }
  return null
}

export function getOptionsOfCheckboxGroup(selectedData: string[], fullData: IOption[]) {
  const selectedSet = new Set(selectedData)

  return getValidArray(fullData).map((item) => ({
    ...item,
    checked: selectedSet.has(item.value),
  }))
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export function removeAccents(str) {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
}

export function chakraShouldForwardProp<AdditionalProps>(CkComponent: FC<AdditionalProps>, baseStyle: BaseStyle) {
  return chakra(CkComponent, {
    shouldForwardProp: (prop: string) => {
      // *INFO:  don't forward Chakra's props
      const isChakraProp = !shouldForwardProp(prop)
      if (isChakraProp) return false

      // *INFO: forward valid HTML props
      const isValidProp = isValidHTMLProp(prop)
      if (isValidProp) return true

      // *INFO: else, only forward `sample` prop
      return ['sample'].includes(prop)
    },
    baseStyle,
  })
}

export function hexToRgbA(hex: string, opacity: number) {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${opacity})`
  }
  throw new Error('Bad Hex')
}

export function convertTextToColor(color: string, opacity: number) {
  const isHexaColor = color?.includes('#')
  let backgroundColor: string
  if (isHexaColor) {
    backgroundColor = hexToRgbA(color, opacity)
  } else {
    backgroundColor = color?.substring(0, color?.length - 1)
  }
  return backgroundColor
}

export function insertString(string: string, index: number, substring: string) {
  if (index < 0) {
    return string
  }
  return [string.slice(0, index), substring, string.slice(index)].join('')
}

export function getDisplayName(user: { displayName?: string; fullName?: string }) {
  return user?.displayName || user?.fullName
}

export function getArrayValueFromParsedQuery(
  parsed: queryString.ParsedQuery<string | boolean>,
  name: string
): Array<string> {
  if (parsed[name] === undefined) return []
  if (typeof parsed[name] === 'string') return [String(parsed[name])]
  return parsed[name] as Array<string>
}

export function getLastDayOfPreviousMonth(timezone = 'Asia/Ho_Chi_Minh') {
  return dayjs().subtract(1, 'month').endOf('month').tz(timezone).format('YYYY-MM-DD')
}

export function convertQueryStringToFilter<T extends { title?: string; year?: string; published?: boolean }>(
  queryStringValue: string
): T {
  const queryStringObject = queryString.parse(queryStringValue, { parseBooleans: true })
  const filter = {} as T

  if (queryStringObject.title) filter.title = queryStringObject.title as string
  if (queryStringObject.year) filter.year = queryStringObject.year as string
  if (queryStringObject.status) filter.published = queryStringObject.status === 'published'

  return filter
}

export function convertFilterToQueryString<T extends { title?: string; year?: string; published?: boolean }>(
  filter: T
): string {
  const queryStringObject: Record<string, string | undefined> = {
    title: filter.title,
    year: filter.year,
    status: filter.published !== undefined ? (filter.published ? 'published' : 'unpublished') : undefined,
  }

  Object.keys(queryStringObject).forEach((key) => {
    if (queryStringObject[key] === undefined || queryStringObject[key] === '') {
      delete queryStringObject[key]
    }
  })

  return queryString.stringify(queryStringObject)
}

export function formatDate(dateString: string): string {
  const date = dayjs(dateString)

  if (!date.isValid()) {
    throw new Error('Invalid date format')
  }

  return date.tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD')
}
