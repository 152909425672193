import { useEffect } from 'react'
import { FormControl, FormLabel, Stack, Radio, RadioGroup } from '@chakra-ui/react'
import { capitalize } from 'lodash'
import { observer } from 'mobx-react'
import { useFormContext, useWatch, Controller } from 'react-hook-form'
import { getValidArray } from 'utils/commonUtils'

export interface IGroupRadio {
  name: string
  label?: any
  isEdit?: boolean
  data?: string[]
  defaultValue?: string
}

const GroupRadio = (props: IGroupRadio) => {
  const { name, label, data } = props
  const { control, setValue } = useFormContext()
  const currentValue: string = useWatch({ control, name })

  useEffect(() => {
    if (currentValue === '') {
      setValue(name, '')
    }
  }, [currentValue, name, setValue])

  return (
    <FormControl id={name} width="full" padding={{ sm: '10px', md: '10px', lg: '0' }}>
      <FormLabel fontWeight={'nomal'} mb={2} color="gray.700" paddingBottom="12px">
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        // NOTE: this part must be allowed null value for suitable with filter logic in filterAnnouncement function
        render={({ field }) => (
          <RadioGroup {...field} value={currentValue ?? ''} onChange={(value) => field.onChange(value || null)}>
            <Stack direction="row">
              {getValidArray(data).map((item) => (
                <Radio key={item} value={item} colorScheme="blue" width="50%">
                  {capitalize(item)}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}

export default observer(GroupRadio)
