import { IProjectNameResponse } from 'API/project/constants'
import { ECalendarViewType } from 'components/Calendar/constants'
import { makeColorBolder } from 'components/Calendar/utils'
import { toJS } from 'mobx'
import { IProject } from 'types/project'
import { IOption } from 'constants/common'
import { DEFAULT_PROJECT_COLOR } from 'constants/common'
import { isValidArray, getValidArray } from 'utils/commonUtils'
import { IPayloadOfAutoSelectTaskType } from '../../constants'
import { IProjectItem } from '../components/Dropdown/DropdownButton'

export function getLabelViewMode(currentView: ECalendarViewType): string {
  switch (currentView) {
    case ECalendarViewType.DAY_GRID_MONTH:
      return 'Month'
    case ECalendarViewType.TIME_GRID_WEEK:
      return 'Week'
    case ECalendarViewType.TIME_GRID_DAY:
      return 'Day'
    default:
      return ''
  }
}

export function selectTaskTypeFromTaskNameAndDescription(payload: IPayloadOfAutoSelectTaskType) {
  const { taskTypeList, taskName = '', description = '', currentTaskTypeList = [] } = payload
  const taskTypeListData = toJS(taskTypeList)
  const results = [...currentTaskTypeList]

  const lowerCaseTaskName = taskName.toLowerCase()
  const lowerCaseDescription = description.toLowerCase()

  taskTypeListData.forEach((item) => {
    const isMatch = getValidArray(item.keywords).some((keyword) => {
      const lowerCaseKeyword = keyword.toLowerCase()
      return lowerCaseTaskName.includes(lowerCaseKeyword) || lowerCaseDescription.includes(lowerCaseKeyword)
    })

    if (isMatch) {
      const newItem = { label: item.value, value: item.id }
      const isIncludedInCurrentTaskType = results.some((currentTaskType) => currentTaskType.value === item.id)

      if (!isIncludedInCurrentTaskType) {
        results.push(newItem)
      }
    }
  })

  return results
}

export function generateProjectItems(projects: IProjectNameResponse[], filteredProjects: IOption[]): IProjectItem[] {
  if (!isValidArray(projects)) {
    return []
  }
  let selectedProjects: IProjectNameResponse[] = projects
  if (filteredProjects && isValidArray(filteredProjects)) {
    selectedProjects = projects.filter((project: IProject) => {
      return filteredProjects.some((filteredProject: IOption) => {
        return filteredProject.value === project.id
      })
    })
  }
  return getValidArray(selectedProjects).map((project: IProject) => {
    return {
      title: project?.name,
      value: project?.id,
      borderColor: makeColorBolder(project?.color ?? DEFAULT_PROJECT_COLOR),
      color: project?.color ?? DEFAULT_PROJECT_COLOR,
      paymentCategory: project?.paymentCategory,
      budgetRemain: project?.budgetRemain,
      currencyName: project?.currencyName,
    }
  })
}
