export interface IOption<T = string> {
  label: T
  value: string
  text?: string
  color?: string
}

export interface IOptionWithElement {
  value: string
  label: JSX.Element
}

export const maxMobileSize = '(max-width: 576px)'
export const tabletSize = '(min-width: 577px) and (max-width: 1199px)'
export const maxTabletSize = '(max-width: 1199px)'
export const laptopSize = '(max-width: 1200px)'
export const minLaptopSize = '(min-width: 1200px)'
export const NotificationId = 'notificationId'
export const UserId = 'userId'
export const DEFAULT_PROJECT_COLOR = '#eeeee4'
