import { useEffect, useState } from 'react'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import ReactQuill from 'react-quill'
import './styles.scss'
import { EDITOR_MODULES } from './constants'
import { IEditor } from './interface'

const Editor = (props: IEditor) => {
  const { name, placeholder, label } = props
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const value = watch(name) || ''

  function handleFocus(): void {
    setIsFocused(true)
  }

  function handleBlur(): void {
    setIsFocused(false)
  }

  function handleChange(value: string): void {
    setValue(name, value)
  }

  useEffect(() => {
    register(name)
  }, [name])

  return (
    <FormControl isInvalid={Boolean(errors?.description)}>
      <FormLabel fontSize="md" fontWeight="400">
        {label}
      </FormLabel>
      <ReactQuill
        value={value}
        placeholder={placeholder}
        modules={EDITOR_MODULES}
        theme="snow"
        className={`textarea ${Boolean(errors?.description) ? 'description-error' : ''} ${isFocused ? 'focused' : ''}`}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
    </FormControl>
  )
}
export default Editor
